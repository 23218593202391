import { Injectable, Injector } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpHeaders,
  HttpErrorResponse,
  HttpEvent,
} from '@angular/common/http';
import { from, throwError, of, Observable, combineLatest, merge } from 'rxjs';
import { Platform } from '@ionic/angular';
import { NativeHttpHandler } from './native-http-handler';
import { catchError, delay, mergeMap, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MockData } from 'src/app/mock-api/helper/mock-data';
import { SecureStorageService } from '@services/storage/secure-storage.service';
import { ApiErrorHandlerService } from '@services/api/api-error-handler.service';
import { StorageKey } from '../helpers/enum';
import { UserStore } from '../stores/user.store';
import { LoginService } from '@services/api/login.service';
import { PlatformUtilService } from './platform-util.service';

//https://manuel-heidrich.dev/blog/an-elegant-way-to-handle-native-http-requests-with-cordova-advanced-http-in-an-ionic-app/

// Intercept angular http to call ionic native http
// Limitation when using ionic native http, authInterceptor will not be trigger to check token.
import { PublicApiUtilService } from './general/public-api-util.service';
import { ViewAgentApiUtilService } from './general/view-agent-api-util.service';
import { OktaAuthService } from '@services/okta/okta-auth-service';
import { ViewAsAgentStore } from 'src/app/stores/view-as-agent.store';

type HttpMethod = 'get' | 'post' | 'put' | 'patch' | 'head' | 'delete' | 'upload' | 'download';
@Injectable()
export class NativeHttpInterceptor implements HttpInterceptor {
  private nativeHttp: NativeHttpHandler;
  private platform: Platform;
  private platformUtil: PlatformUtilService;
  private secureStorageService: SecureStorageService;
  private loginService: LoginService;
  private apiErrorHandlerService: ApiErrorHandlerService;
  private publicApiUtil: PublicApiUtilService;
  private viewAgentApiUtilService: ViewAgentApiUtilService;
  private userStore: UserStore;
  private viewAsAgentStore: ViewAsAgentStore;

  constructor(private injector: Injector, private oktaAuthService: OktaAuthService) {
    this.nativeHttp = this.injector.get<NativeHttpHandler>(NativeHttpHandler);
    this.platform = this.injector.get<Platform>(Platform);
    this.platformUtil = this.injector.get<PlatformUtilService>(PlatformUtilService);
    this.secureStorageService = this.injector.get<SecureStorageService>(SecureStorageService);
    this.loginService = this.injector.get<LoginService>(LoginService);
    this.apiErrorHandlerService = this.injector.get<ApiErrorHandlerService>(ApiErrorHandlerService);
    this.publicApiUtil = this.injector.get<PublicApiUtilService>(PublicApiUtilService);
    this.viewAgentApiUtilService = this.injector.get<ViewAgentApiUtilService>(ViewAgentApiUtilService);
    this.userStore = this.injector.get<UserStore>(UserStore);
    this.viewAsAgentStore = this.injector.get<ViewAsAgentStore>(ViewAsAgentStore);
  }

  async token(key: string) {
    const value = await this.secureStorageService.get(key);
    return Promise.resolve(value);
  }

  private isRefreshing: boolean = false

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const useHttpCLient = this.platform.is('mobileweb') || !this.platform.is('capacitor');

    /* only in dev environment */
    const isMockData = environment.isMockApi;
    // Added includes to prevent from intercepting download pdf feature for native devices
    if (isMockData && !request.url.includes('assets/pdf-test.pdf')) {
      return of(
        new HttpResponse({
          status: 200,
          body: MockData.get(request.url),
        })
      ).pipe(delay(environment.mockApiDelay));
    }

    return from(this.token(StorageKey.TokenExpiry)).pipe(
      switchMap((expiryDate) => {
        var isSkippingRefreshToken = this.publicApiUtil.isPublicApi(request.url);

        const currentDate = new Date().getTime();
        var isTokenExpired = false;
        var tokenExpiryDate = new Date(expiryDate).getTime();
        // determine the expires Datetime
        if (tokenExpiryDate <= currentDate) {
          isTokenExpired = true;
        } else {
          isTokenExpired = false;
        }

        if (isTokenExpired && !isSkippingRefreshToken) {
          // call refresh token api

          /** Commented out/disabled refreshtoken call API */
          // return this.loginService.refreshToken().pipe(
          //   tap(() => {}),
          //   switchMap(() => {
          // Continue the original api
          return from(
            useHttpCLient
              ? this.handleHttpClientRequest(request, next)
              : this.handleHttpNativeRequest(request)
          ).pipe(
            tap((event) => {
              if (event instanceof HttpResponse) {
                // console.warn(`API ${request.method} REQUEST = ${request.url}`, event.body);
              }
            }),
            catchError((error) => {
              // console.error(`API ${request.method} REQUEST = ${request.url}`, error);
              this.apiErrorHandlerService.setError(error);
              return useHttpCLient ? throwError(error) : this.handleNativeError(error, request.url);
            })
          );
          //   })
          // );
        } else {
          // call api as usual
          return from(
            useHttpCLient
              ? this.handleHttpClientRequest(request, next)
              : this.handleHttpNativeRequest(request)
          ).pipe(
            tap((event) => {
              if (event instanceof HttpResponse) {
                // console.warn(`API ${request.method} REQUEST = ${request.url}`, event.body);
              }
            }),
            catchError((error) => {
              this.apiErrorHandlerService.setError(error);
              // console.error(`API ${request.method} REQUEST = ${request.url}`, error);
              return useHttpCLient ? throwError(error) : this.handleNativeError(error, request.url);
            })
          );
        }
      })
    );
  }


  /**
   * this function is for Web
   */
  async handleHttpClientRequest(request: HttpRequest<any>, next: HttpHandler) {
    await this.handleInternetConnection();

    request = await this.handleAppendRequestHeader(request);
    const headerKeys = request.headers.keys();
    const header = {};
    headerKeys.forEach((key) => {
      header[key] = request.headers.get(key);
    });

    return next.handle(request).toPromise();
  }

  /**
   * this function if for Mobile
   */
  private async handleHttpNativeRequest(request: HttpRequest<any>): Promise<HttpResponse<any>> {
    request = await this.handleAppendRequestHeader(request);

    const headerKeys = request.headers.keys();
    const header = {};
    headerKeys.forEach((key) => {
      header[key] = request.headers.get(key);
    });

    // Params value has to be set to string to work
    // https://github.com/silkimen/cordova-plugin-advanced-http/issues/242
    const paramKeys = request.params.keys();
    const params = {};
    paramKeys.forEach((key) => {
      params[key] = request.params.get(key) != null ? request.params.get(key).toString() : '';
    });

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const method = <HttpMethod>request.method.toLowerCase();

    try {
      await this.platform.ready();
      await this.handleInternetConnection();

      const nativeHttpResponse = await this.nativeHttp.sendRequest(
        method,
        request.url,
        request.body,
        params,
        header,
        request.responseType
      );

      let body;

      try {
        body = nativeHttpResponse.data;
      } catch (error) {
        body = { response: nativeHttpResponse.data };
      }

      return Promise.resolve(
        new HttpResponse({
          body,
          status: nativeHttpResponse.status,
          headers: nativeHttpResponse.headers as any,
          url: nativeHttpResponse.url,
        })
      );
    } catch (error) {
      if (!error.status) {
        return Promise.reject(error);
      } else if (error.status === -2) {
        // Catch SSL handshake error (iOS & Android)
        return Promise.reject(
          new HttpResponse({
            body: error.error,
            status: 495,
            headers: new HttpHeaders(),
            url: '',
          })
        );
      } else if (error.status === -6 || error.status === -4) {
        // Not able to connect to host
        // https://github.com/silkimen/cordova-plugin-advanced-http/blob/master/www/error-codes.js
        return Promise.reject(
          new HttpResponse({
            body: error.error,
            status: 503,
            headers: new HttpHeaders(),
            url: '',
          })
        );
      }

      let err;

      try {
        err = JSON.parse(error.error);
      } catch (e) {
        err = error.error;
      }

      err = err?.error ? err.error : err;

      return Promise.reject(
        new HttpResponse({
          body: err,
          status: error.status,
          headers: error.headers,
          url: error.url,
        })
      );
    }
  }

  // Check for network connection before sending request
  private async handleInternetConnection() {
    // const connected = await this.checkInternetPopupService.getConnectedStatus();
    // if (!connected) {
    //   this.checkInternetPopupService.openDisconnectModal();
    if (false) {
      const response = new HttpErrorResponse({
        error: '{"error":"Please check your internet connection and try again"}',
        status: 503,
        headers: new HttpHeaders(),
        url: '',
      });
      return Promise.reject(response);
    }
  }

  /**
   * Add values into HTTP request Header
   */
  private async handleAppendRequestHeader(request: HttpRequest<any>): Promise<HttpRequest<any>> {
    // Refresh token api will use RefreshToken as Bearer token

    const apiVersion = environment.apiVersion;

    var isOktaRefreshTokenApi =
      request.url.replace(environment.apiEndpoint, '') == `/${apiVersion}/auth/oktarefreshtoken`;

    var isRefreshTokenApi =
      request.url.replace(environment.apiEndpoint, '') == `/${apiVersion}/auth/refreshtoken`;

    var isKillSessionApi =
      request.url.replace(environment.apiEndpoint, '') == `/${apiVersion}/auth/killSession`;

    var isGenTokenApi =
      request.url.replace(environment.apiEndpoint, '') == `/${apiVersion}/auth/genToken`;

    var isChangePassApi =
      request.url.replace(environment.apiEndpoint, '') == `/${apiVersion}/agent/changepassword`;

    var isPublicApi = this.publicApiUtil.isPublicApi(request.url);

    var token: string | null;

    if (isRefreshTokenApi) {
      token = await this.secureStorageService.get(StorageKey.RefreshToken);
    } else {
      token = await this.secureStorageService.get(StorageKey.AccessToken);
    }

    var oToken: string | null;

    oToken = await this.getOAccessToken();
    if (isChangePassApi) {
      oToken = this.oktaAuthService.loggedInAccessToken;
      setTimeout(() => {
        this.secureStorageService.set(
          StorageKey.OAccessToken,
          this.oktaAuthService.loggedInAccessToken
        );
      }, 1000);
    } else {
      oToken = await this.getOAccessToken();
    }

    const reqHeaders: { [name: string]: string | string[] } = {};

    reqHeaders['devicePlatform'] = this.platformUtil.getDevicePlatform;

    // need get the url path get refrestoken
    if (!isPublicApi) {
      if (token && token != null) {
        reqHeaders['Authorization'] = `Bearer ${token}`;
        reqHeaders['oToken'] = oToken;
      }
    } else {
      if (isKillSessionApi) {
        //Special handle for killSession API as in some case need to pass accessToken, while some not, when type is == 3, need to pass
        if (request.body.body.type == 3) {
          reqHeaders['Authorization'] = `Bearer ${token}`;
          reqHeaders['oToken'] = oToken;
        }
      }
    }

    if (isGenTokenApi) {
      reqHeaders['oToken'] = oToken;
    }

    const isViewAsAgentApi = this.viewAgentApiUtilService.isViewAsAgentApi(request.url);

    if (isViewAsAgentApi && this.viewAsAgentStore.getIsViewing) {
      if (request.headers.get('agentCode') === null || request.headers.get('agentCode') === '') {
        reqHeaders['agentCode'] = this.viewAsAgentStore.state.agentCode;
      }
    }

    reqHeaders['Pragma'] = 'no-cache';
    reqHeaders['Cache-Control'] = 'no-store';

    return request.clone({
      setHeaders: reqHeaders,
    });
  }

  async getOAccessToken() {
    return await this.secureStorageService.get(StorageKey.OAccessToken);
  }

  async getORefreshToken() {
    return await this.secureStorageService.get(StorageKey.ORefreshToken);
  }

  private handleNativeError(error: any, url: string) {
    const errorCode =
      error?.body instanceof Array ? error?.body[0]?.errorCode : error?.body?.errorCode;
    const httpError = new HttpErrorResponse({
      error: error.body,
      headers: error.headers,
      status: error.status,
      statusText: error.statusText,
      url: error.url,
    });
    return throwError(httpError);
  }
}
